import React, { useEffect, useState } from "react";
import classes from "./launchpad.module.css";
import Slider from "../../components/slider/Slider";
import CardMint from "../../components/card-mint/CardMint";
import key from "../../img/key.gif";
import cube from "../../img/cube-pattern.png";
import bl from "../../img/bl.png";
import axios from "axios";
import { Link } from "react-router-dom";

const LaunchPad = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const get_cards = async () => {
      const res = await axios.post(
        "https://admin.blastone.one/api.php?action=get_cards"
      );
      setCards(res.data.data);
      console.log(res.data.data)
    };
    get_cards();
  }, []);

  return (
    <div style={{ minHeight: "100vh", marginTop: "72px" }}>
      <Slider />
      <div className={classes.block}>
        <div className={classes.cube}>
          <img loading="lazy" src={cube} alt="cube image" />
        </div>
        <p className={classes.header}>Upcoming</p>
        <div className={classes.cards}>
          {cards
            .filter((el) => Number(el.status))
            .map((e) => {
              return (
                <Link to={e.pr_url}>
                  <CardMint
                    img={`https://admin.blastone.one/uploads/${e.img}`}
                    end_date={e.end_in}
                    header={e.header}
                    startsIn={e.starts_in}
                    time={e.start_time}
                    date={e.starts_in}
                    supply={e.supply}
                    id={e.id}
                    status={"Mint"}
                    starts={"Starts in"}
                  />
                </Link>
              );
            })}
          {/* <CardMint
            img={key}
            header={"BlastOne: Key"}
            startsIn={"2d 01h 13m"}
            supply={"1000"}
            status={"Mint"}
            starts={"Starts in"}
          /> */}
          {/* <CardMint
            img={key}
            header={"BlastOne: Key"}
            startsIn={"2d 01h 13m"}
            supply={"444"}
            status={"Mint"}
          />
          <CardMint
            img={key}
            header={"BlastOne: Key"}
            startsIn={"2d 01h 13m"}
            supply={"444"}
            status={"Mint"}
          /> */}
        </div>
      </div>
      <div className={classes.block}>
        <p className={classes.header}>Previous</p>
        <div className={classes.cards}>
          {cards
            .filter((el) => !Number(el.status)).reverse()
            .map((e) => {
              return (
                <Link to={e.pr_url}>
                  <CardMint
                    img={`https://admin.blastone.one/uploads/${e.img}`}
                    header={e.header}
                    startsIn={'Closed'}
                    // time={e.start_time}
                    // date={e.starts_in}
                   
                    supply={e.supply}
                    status={"Sold out"}
                    starts={"End in"}
                  />
                </Link>
              );
            })}
          {/* <CardMint
            img={key}
            header={"BlastOne: Key"}
            startsIn={"Open"}
            supply={"444"}
            status={"Sold out"}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default LaunchPad;
