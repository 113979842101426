import React from "react";
import classes from "./mint-zone.module.css";
import key from "../../img/key.gif";
import { useWriteContract, useReadContract, useAccount } from "wagmi";
import { abi } from "./abi.ts";

const MintZone = () => {
  const { writeContractAsync } = useWriteContract();
  const saleAddress = "0x19E6222bB9D67E51c1f24b2Ffb6499BE29b1C446";
  const { isConnected, address } = useAccount();

  let totalSold = useReadContract({
    abi: abi,
    address: saleAddress,
    functionName: "getTotalSoldTokens",
  });
  totalSold = totalSold.data ? totalSold.data : 0;

  return (
    <div className={classes.key}>
      <div className={classes.bg}></div>
      <div className={classes.data}>
        <img loading="lazy" src={key} alt="" className={classes.key_img} />
        <div className={classes.texts}>
          <span className={classes.header}>BlastOne: Key</span>
          <p className={classes.info}>
            Early Beta Testing Access: Early backers will be given exclusive
            access to the beta testing phase of the platform, allowing them to
            explore features, provide feedback, and influence the development of
            the platform before the public launch.
          </p>
          <div>
            <div className={classes.line}></div>
            {/* <span className={classes.score}>{totalSold} of 1000</span> */}
            <span className={classes.score}>0 of 1000</span>
          </div>
          <button
            className={classes.mint}
            disabled
            // disabled={!isConnected}
            // onClick={async () => {
            //   try {
            //     await writeContractAsync({
            //       abi: abi,
            //       address: saleAddress,
            //       account: address,
            //       functionName: "purchase",
            //       params: [],
            //       value: "0",
            //     });
            //   } catch (e) {
            //     console.log(e.message);
            //     alert(e.message || "An error occurred. Please try again.");
            //   }
            //   window.location.reload();
            // }}
          >
            Mint for Free
          </button>
        </div>
      </div>
    </div>
  );
};

export default MintZone;
