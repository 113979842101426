import React from "react";
import classes from "./main-page.module.css";
import MintZone from "../../components/mint-zone/MintZone";
import cube from "../../img/cube-pattern (1).png";

const MainPage = () => {
  return (
    <>
      <div className={classes.page}>
        <MintZone />
      </div>
      <div className={classes.cubblock}>
        <img loading="lazy" src={cube} alt="" className={classes.cub} />
      </div>
    </>
  );
};

export default MainPage;
