import React, { useState } from "react";
import classes from "./card-mint.module.css";
import Timer from "../timer/Timer";

const CardMint = ({
  id,
  header,
  img,
  supply,
  startsIn,
  status,
  starts,
  time,
  date,
  end_date,
}) => {

  return (
    <div className={classes.card}>
      <div className={classes.bg}></div>
      <div className={classes.data}>
        <img
          loading="lazy"
          src={img}
          alt="card image"
          height={"210px"}
          width={"218px"}
          className={classes.img}
        />
        <p className={classes.header}>{header}</p>
        <div className={classes.info}>
          <div className={classes.text} id={classes.d}>
            {id == 17 || id == 20 || id == 21 || id == 22 ? (
              <p>{"Ends in"}</p>
            ) : (
              <p>{"Starts in"}</p>
            )}

            {status === "Sold out" ? (
              <span>{startsIn}</span>
            ) : (
              <Timer end_date={end_date} time={time} date={date} />
            )}
            {/* <span>{startsIn}</span> */}
          </div>
          <div className={classes.text}>
            <p>Supply</p>
            <span>{supply}</span>
          </div>
        </div>
        <button className={classes.mint} disabled={status !== "Mint"}>
          {status}
        </button>
      </div>
    </div>
  );
};

export default CardMint;
