import React, { useState, useEffect } from "react";

const Timer = ({date, time, end_date}) => {
  const parseDateTime = (dateString, timeString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    const [hour, minute] = timeString.split(':').map(Number);
    return new Date(Date.UTC(year, month - 1, day, hour, minute));
  };

  const calculateTimeLeft = (targetDate) => {
    const now = new Date();
    const utcNow = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
      )
    );
    const difference = targetDate - utcNow;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        d: Math.floor(difference / (1000 * 60 * 60 * 24)),
        h: Math.floor((difference / (1000 * 60 * 60)) % 24),
        m: Math.floor((difference / 1000 / 60) % 60),
      };
    }

    return timeLeft;
  };

  const [dateString, setDateString] = useState(date);
  const [timeString, setTimeString] = useState(time);

  const targetDate = parseDateTime(dateString, timeString);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
  const [timeUp, setTimeUp] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const timeLeft = calculateTimeLeft(targetDate);
      if (now >= targetDate) {
        setTimeUp(true);
        clearInterval(timer);
      } else {
        setTimeLeft(timeLeft);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const formatTime = (time) => {
    return `${time.d != undefined ? time.d : ''}d ${time.h != undefined ? String(time.h).padStart(2, '0') : ''}h ${time.m != undefined ? String(time.m).padStart(2, '0') : ''}m`;
  };

  if(timeUp) {
    setDateString(end_date)
    setTimeString("17:00")
    setTimeUp(false)
  }

  return (
    <span>
      {timeUp ? "" : formatTime(timeLeft)}
    </span>
  );
};

export default Timer;
