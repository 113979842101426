import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainPage from "./pages/main-page/MainPage";
import Header from "./components/header-desktop/Header";
import LaunchPad from "./pages/launchpad-page/LaunchPad";
import Footer from "./components/footer/Footer";
import MobileMenu from "./components/mobile-menu/MobileMenu";
import MintPage from "./pages/mint-page/MintPage";
import { useState } from "react";
import { useEffect } from "react";

import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { WagmiProvider } from "wagmi";
import { blast } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MintPage2 from "./pages/mint-page/MintPage2";
import MintPage3 from "./pages/mint-page/MintPage3";

const queryClient = new QueryClient();

const projectId = "a5ab077abc409be536db2c77bc659157";

const metadata = {
  name: "BlastOne",
  description: "BlastOne Marketplace",
  url: "https://twitter.com/Blastone_art",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [blast];
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
});

export function Web3ModalProvider({ children }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}

function App() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  useEffect(() => {
    const preventHorizontalScroll = (e) => {
      if (e.touches && e.touches.length === 1) {
        const touch = e.touches[0];
        if (touch.clientX < 10 || touch.clientX > window.innerWidth - 10) {
          e.preventDefault();
        }
      }
    };

    // Adding event listener to prevent default horizontal touchmove behavior
    document.body.addEventListener("touchmove", preventHorizontalScroll, {
      passive: false,
    });

    return () => {
      // Cleanup event listener on component unmount
      document.body.removeEventListener("touchmove", preventHorizontalScroll);
    };
  }, []);

  return (
    <>
      <Web3ModalProvider>
        <Router>
          <Header
            isMenuOpen={showMobileMenu}
            onOpenMenu={() => setShowMobileMenu(!showMobileMenu)}
          />
          {showMobileMenu && <MobileMenu />}
          <Routes>
            <Route path="/*" element={<MainPage />} />
            <Route path="/launchpad" element={<LaunchPad />} />
            {/* <Route path="/launchpad/box" element={<MintPage />} /> */}
            {/* <Route path="/launchpad/monoswap" element={<MintPage2 />} /> */}
            {/* <Route path="/launchpad/tornado" element={<MintPage3 />} /> */}
          </Routes>
          <Footer />
        </Router>
      </Web3ModalProvider>
    </>
  );
}

export default App;
